<template>
   <div>
      <AmcText
         v-for="(status, index) in situations"
         :key="index"
         class="d-flex flex-row justify-content-between border-bottom"
      >
         <AmcText class="p-3">
            <AmcDot :class="`${status.state}`"></AmcDot>
            <AmcText inline> {{ status.company }}</AmcText>
         </AmcText>
         <AmcText class="p-3"> {{ status.date }}</AmcText>
      </AmcText>
   </div>
</template>

<script>
export default {
   name: 'AmcStateList',
   props: {
      situations: {
         type: [Array, Object],
         default: () => {},
      },
   },
};
</script>

<style scoped>
.state-dot {
   display: inline-block;
   height: 10px;
   width: 10px;
   border-radius: 100%;
   margin-right: 12px;
}

.alarm {
   background-color: #ea3d2f;
}

.fault {
   background-color: #f0d961;
}

.offline {
   background-color: #f0f1f2;
}

.online {
   background-color: #2fa84f;
}
</style>
